module.exports = {
    siteMetadata: {
        title: `GMX Dev Site`,
        siteUrl: `https://gmx-staging.vonnda.net/`,
        backendUrl: `https://gmxwp-staging.vonnda.net/`,
        microserviceUrl: `https://gmxwp-staging.vonnda.net/gmx-micro/`,
        forgotPasswordUrl: `https://www.gmetrix.net/Public/PasswordRecovery.aspx`,
        bigCommerceStoreHash: `ee4uj7q2w8`,
        bigCommerceToken: `9tgs9vpoxsd4zaz904cpegl8uv0vled`
    },
    plugins: [
        {
            resolve: 'gatsby-source-wordpress',
            options: {
                "url": "https://gmxwp-staging.vonnda.net/graphql",
                production: {
                    hardCacheMediaFiles: true,
                }
            }
        },
        {
            resolve: `gatsby-plugin-manifest`,
            options: {
                name: `Gatsby Sydney Ecommerce Theme`,
                short_name: `Sydney`,
                start_url: `/`,
                background_color: `#000000`,
                theme_color: `#ffffff`,
                display: `standalone`,
                icon: 'src/assets/favicon.png',
            },
        },
        "gatsby-plugin-netlify",
        "gatsby-plugin-image",
        "gatsby-plugin-sharp",
        "gatsby-transformer-sharp",
        "gatsby-plugin-sitemap",
        "gatsby-plugin-sass",
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                "name": "images",
                "path": "./src/images/"
            },
            __key: "images"
        }
    ],
};

